import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DeloitteAiBannerLinkComponent } from "./ui/banner-link/banner-link.component";
import { DeloitteAiContactBannerComponent } from "./ui/contact-banner/contact-banner.component";
import { DeloitteAiPageBannerComponent } from "./ui/page-banner";

@NgModule({
    declarations: [
        DeloitteAiPageBannerComponent,
        DeloitteAiContactBannerComponent,
        DeloitteAiBannerLinkComponent,
    ],
    imports: [CommonModule],
    exports: [
        DeloitteAiPageBannerComponent,
        DeloitteAiContactBannerComponent,
        DeloitteAiBannerLinkComponent,
    ],
    providers: [],
})
export class DeloitteAiBannerModule { }
