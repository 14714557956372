
import { Injectable, Optional, SkipSelf } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LocalizationServiceConfig } from "./localization-config.service";

@Injectable()
export class LocalizationService {
  private _localeId = "en-US";

  constructor(
    @Optional() @SkipSelf() private singleton: LocalizationService,
    private config: LocalizationServiceConfig,
    private translateService: TranslateService
  ) {
      if (this.singleton) {
          throw new Error(
              "LocalizationService is already provided by the root module"
          );
      }
      this._localeId = this.config.locale_id;
  }

  public initService(): Promise<void> {
      this._localeId = localStorage.getItem("language") || "en-US";
      return this.useLanguage(this._localeId);
  }

  public getLanguage(): string {
      return this._localeId;
  }

  public useLanguage(lang: string): Promise<void> {
      this._localeId = lang;
      this.translateService.setDefaultLang(lang);
      return this.translateService
          .use(lang)
          .toPromise()
          .catch(() => {
              throw new Error("LocalizationService.init failed");
          });
  }
  public translate(key: string | string[], interpolateParams?: unknown): string {
      return this.translateService.instant(key, interpolateParams) as string;
  }
}
