import { AfterContentChecked, Component, ElementRef, ViewChild } from "@angular/core";
import { ellipsis } from "ellipsed";

@Component({
    selector: "deloitteai-ellipsed",
    templateUrl: "./ellipsed.html",
})
export class DeloitteAiEllipsedComponent implements AfterContentChecked {

    @ViewChild("placeholder", { static: true, read: ElementRef })
    private textPlaceholder: ElementRef;

    @ViewChild("ellipsed", { static: true, read: ElementRef })
    private ellipsedText: ElementRef;

    private previousText = "";

    ngAfterContentChecked(): void {
        if (this.previousText !== this.textPlaceholder.nativeElement.textContent) {
            this.updateEllipsed(this.textPlaceholder.nativeElement.textContent);
            this.previousText = this.textPlaceholder.nativeElement.textContent;
        }
    }

    private updateEllipsed(content: string) {
        this.ellipsedText.nativeElement.innerHTML = content;
        ellipsis(this.ellipsedText.nativeElement, 3, { responsive: true });
    }
}
