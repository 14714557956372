import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customDate'
})

export class CustomDatePipe implements PipeTransform {

    transform(value: any, format = 'dd LLL'): string {

        const date = new Date(value);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();

        let formattedDate: string;
        if (format === 'dd LLL') {
            formattedDate = `${day} ${month}`;
        } else if (format === 'dd LLL yyyy') {
            formattedDate = `${day} ${month} ${year}`;
        } else {
            formattedDate = date.toString();
        }
        return formattedDate;
    }
}
