import { APP_BOOTSTRAP_LISTENER, NgModule } from "@angular/core";
import { Router } from "@angular/router";
import { PageNotFoundBootstrapFactory } from "./bootstrap-factory";

@NgModule({
    providers: [{
        provide: APP_BOOTSTRAP_LISTENER,
        multi: true,
        useFactory: PageNotFoundBootstrapFactory,
        deps: [Router]
    }],
})
export class DeloitteAiPage404Module { }
