import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NguCarouselModule } from "@ngu/carousel";
import { SafePipe, CustomCurrencyPipe, CustomDatePipe } from "./pipes/index";
import { DeloitteAiBenefitsComponent } from "./ui/benefits";
import { DeloitteaiBenefitsItemDirective } from "./ui/benefits-item";

import { DeloitteAiUserResolver } from "./utils/user-resolver";
import { DeloitteAiEllipsedComponent } from "./ui/ellipsed";

@NgModule({
    declarations: [
        DeloitteAiBenefitsComponent,
        DeloitteaiBenefitsItemDirective,
        SafePipe,
        CustomCurrencyPipe,
        CustomDatePipe,
        DeloitteAiEllipsedComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NguCarouselModule
    ],
    exports: [
        DeloitteAiBenefitsComponent,
        DeloitteaiBenefitsItemDirective,
        SafePipe,
        CustomCurrencyPipe,
        CustomDatePipe,
        DeloitteAiEllipsedComponent
    ],
    providers: [
        DeloitteAiUserResolver
    ],
})
export class DeloitteAiCommonModule {
}
