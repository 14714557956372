import { AihubAccordionModule } from "@aihub/ui/accordion";
import { CommonModule } from "@angular/common";
import { APP_BOOTSTRAP_LISTENER, NgModule } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { NguCarouselModule } from "@ngu/carousel";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DeloitteAiBannerModule } from "@shared/banner";
import { DeloitteAiCapabilityCarouselModule } from "@shared/capability-carousel";
import { DeloitteAiCommonModule } from "@shared/common/common.module";
import { DeloitteAiVjsPlayerModule } from "@shared/vjs-player";
import { DeloitteAiCommonRoutesFactory } from "./common-pages.bootstrap";
import { DeloitteAiContactPage } from "./contact/contact";
import { FaqPage } from "./faq/faq";
import i18n_en from "./i18n/en.json";
import onboarding_en from "./i18n/onboarding_en.json";
import { ImprintPage } from "./imprint/imprint";
import { ImprintService } from "./imprint/imprint.service";
import { OnboardingPage } from "./onboarding/onboarding";
import { PrivacyPage } from "./privacy/privacy.component";
import { PrivacyService } from "./privacy/privacy.service";
import { TermsOfUsePage } from "./terms/terms-of-use.component";
import { TermsOfUsePageService } from "./terms/terms-of-use.service";





@NgModule({
    declarations: [
        DeloitteAiContactPage,
        OnboardingPage,
        FaqPage,
        ImprintPage,
        PrivacyPage,
        TermsOfUsePage,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        NguCarouselModule,
        AihubAccordionModule,
        DeloitteAiCommonModule,
        DeloitteAiCapabilityCarouselModule,
        DeloitteAiVjsPlayerModule,
        DeloitteAiBannerModule,
        RouterModule,
    ],
    exports: [],
    providers: [
        {
            provide: APP_BOOTSTRAP_LISTENER,
            multi: true,
            useFactory: DeloitteAiCommonRoutesFactory,
            deps: [Router, ImprintService, PrivacyService, TermsOfUsePageService],
        },
    ],
})
export class DeloitteAiCommonPageModule {
    public constructor(translateService: TranslateService) {
        translateService.setDefaultLang("en-US");
        translateService.setTranslation("en", i18n_en, true);
        translateService.setTranslation("en", onboarding_en, true);
    }
}
