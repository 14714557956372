<footer class="py-2" *ngIf="menuItems?.length > 0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-12 text-center" *ngFor="let menu of menuItems; let i = index">
                <ul class="list-unstyled">
                    <ng-container *ngIf="menu.items?.length > 0">
                        <li class="d-inline-block mx-3" *ngFor="let item of menu.items" [innerHTML]="item.content"></li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</footer>