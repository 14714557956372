<ngu-carousel #capabilityCarousel [inputs]="tileConfig" [dataSource]="capabilities" *ngIf="capabilities">

    <ngu-tile *nguCarouselDef="let capabilities; let i = index">
        <div class="card">
            <div class="card__header">
                <img src="{{capabilities.image}}" style="max-width: 100%;">
            </div>
            <div class="card__body">
                {{capabilities.text}}
            </div>
        </div>
    </ngu-tile>

    <div NguCarouselPrev class="slide-left" *ngIf="!capabilityCarousel.isFirst">&lt;</div>
    <div NguCarouselNext class="slide-right" *ngIf="!capabilityCarousel.isLast">&gt;</div>

    <ul class="quick-nav" *ngIf="points.length" NguCarouselPoint>
        <li *ngFor="let pointNumber of capabilityCarousel.pointNumbers;"
            [class.active]="pointNumber === capabilityCarousel.activePoint"
            (click)="capabilityCarousel.moveTo(pointNumber)">
        </li>
    </ul>
</ngu-carousel>