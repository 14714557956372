export const DEFAULT_FOOTER_MENU_ITEMS = [
  {
    items: [
      { content: '<a href="/impressum" id="footer-imprint">Imprint</a>' },
      { content: '<a href="/privacy" id="footer-privacy">Privacy</a>' },
      {
        content:
          '<a href="/terms-of-use" id="footer-terms-of-use">Terms of Use</a>',
      },
    ],
  },
];
