import {
    AfterViewInit,
    ChangeDetectorRef, Component, OnInit
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { from } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "deloitteai-imprint",
    templateUrl: "./imprint.html",
    styleUrls: ["./imprint.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ImprintPage implements OnInit, AfterViewInit {
    constructor(
        private readonly activatedroute: ActivatedRoute,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) { }

    content: any;
    isLoaded = false;

    ngOnInit(): void {
        this.changeDetectorRef.detectChanges();

        this.activatedroute.data
            .pipe(switchMap((data) => from(data.content$)))
            .subscribe((content) => {
                if (content) {
                    this.content = content;
                    this.isLoaded = true;
                }
            });
    }

    ngAfterViewInit(): void {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
}
