import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { DownloadService, ImagesService } from "../services";

@Component({
    selector: 'app-root',
    templateUrl: './download.component.html'
})
export class DownloadComponent implements OnInit {
    public downloadBannerImage: string;

    public constructor(
        private downloadService: DownloadService,
        private route: ActivatedRoute,
        private readonly imagesService: ImagesService
    ) { }

    ngOnInit() {
        this.imagesService.init();
        this.imagesService.images$.subscribe((images) => {
            this.downloadBannerImage = images.downloadBanner;
        });
    }

    public download() {
        this.route.queryParams.pipe(
            switchMap((params) => this.downloadService.fetchDownloadUrl(params)),
        ).subscribe((url) => {
            const a = document.createElement('a');
            a.href = url;
            a.click();
        });
    }
}
