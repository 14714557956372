import { Pipe, PipeTransform } from '@angular/core';
import { CURRENCY_SYMBOLS } from '@shared/common/constants/index';

@Pipe({
    name: 'customCurrency'
})

export class CustomCurrencyPipe implements PipeTransform {
    transform(price: number, currency: string): string {
        const currencySymbol = CURRENCY_SYMBOLS.filter(item => item.code === currency)[0].symbol;
        return `${currencySymbol}${price}`;
    }
}