import { Component, Input, ViewChild } from "@angular/core";
import { NguCarousel, NguCarouselConfig } from "@ngu/carousel";
import { Capability } from "./api";

@Component({
    selector: "deloitteai-capability-carousel",
    templateUrl: "./capability-carousel.html",
    styleUrls: ["./capability-carousel.scss"]
})
export class DeloitteAiCapabilityCarouselComponent {

    public points = [];

    /**
     * hotifx change detection error
     *
     * @see https://github.com/uiuniversal/ngu-carousel/issues/83
     */
    @ViewChild("capabilityCarousel")
    set _myCarousel(myCarousel: NguCarousel<any>) {
        setTimeout(() => {
            this.points = myCarousel.pointNumbers;
        });
    }

    @Input()
    public capabilities: Capability[];

    public tileConfig: NguCarouselConfig = {
        grid: { xs: 2, sm: 2, md: 2, lg: 3, all: 0 },
        speed: 250,
        point: { visible: true },
        load: 2,
        velocity: 0,
        touch: true,
        easing: "cubic-bezier(0, 0, 0.2, 1)"
    };
}
