<div class="deloitteai__banner">

  <div class="deloitteai__banner--page-title">
    <ng-content select="[bannerPageTitle]"></ng-content>
  </div>

  <div class="deloitteai__banner--inner">

    <div class="content__logo">
      <ng-content select="[bannerImage]"></ng-content>
    </div>

    <div class="content__body">
      <div class="content__body--header">
        <ng-content select="[bannerHeader]"></ng-content>
      </div>

      <div class="content__body--content">
        <ng-content select="[bannerBody]"></ng-content>
      </div>

      <div class="content__body--footer">
        <ng-content select="[bannerFooter]"></ng-content>
      </div>
    </div>

  </div>
</div>
