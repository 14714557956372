import { Component, Input } from "@angular/core";

@Component({
    selector: "deloitteai-banner-link",
    templateUrl: "./banner-link.component.html",
    styleUrls: ["./banner-link.component.scss"],
})
export class DeloitteAiBannerLinkComponent {
    @Input()
    public url: string;
}
