/** @aihub modules */
import { AiHubLoaderModule } from "@aihub/ui/loader";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { DeloitteAiCommonPageModule } from "@shared/common-pages/common-pages.module";
import { DeloitteAiFooterModule } from "@shared/footer";
import {
    DeloitteAiHeaderModule /* , HeaderMenuItems, HEADER_MENU_ITEMS */,
    HeaderMenuItems
} from "@shared/header";
import { HEADER_MENU_ITEMS_INJECTION_TOKEN } from "@shared/header/src/header.data";
import { DeloitteAiLoaderInterceptor } from "@shared/interceptors/loader.interceptor";
import { DeloitteAiPage404Module } from "@shared/page_404";
/** @components */
import { DeloitteAiPage } from "./ui/page";




@NgModule({
    declarations: [DeloitteAiPage],
    imports: [
        AiHubLoaderModule,
        TranslateModule,
        RouterModule,
        DeloitteAiCommonPageModule,
        DeloitteAiHeaderModule,
        DeloitteAiFooterModule,
        DeloitteAiPage404Module,
    ],
    exports: [DeloitteAiPage],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DeloitteAiLoaderInterceptor,
            multi: true,
        },
    ],
})
export class DeloitteAiPageModule {
    public static forRoot(
        menuData: HeaderMenuItems
    ): ModuleWithProviders<DeloitteAiPageModule> {
        return {
            ngModule: DeloitteAiPageModule,
            providers: [
                {
                    provide: HEADER_MENU_ITEMS_INJECTION_TOKEN,
                    useValue: menuData,
                },
            ],
        };
    }
}
