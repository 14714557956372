import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { FaqResponse, FaqSection } from "./faq.models";

@Injectable({
    providedIn: "root",
})
export class FaqService {
    faqSections$ = new BehaviorSubject([] as FaqSection[]);

    constructor(private http: HttpClient) { }

    init(): any {
        this.getData().subscribe((res) => this.faqSections$.next(res?.faqSections));
    }

    getData(): Observable<FaqResponse> {
        return this.http.get<FaqResponse>(
            "/assets/data/faq.json"
        );
    }
}
