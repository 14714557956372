import { Component } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DEFAULT_FOOTER_MENU_ITEMS } from "./footer.data";
import { FooterService } from "./footer.service";
import { Router } from "@angular/router";

@Component({
  selector: "deloitteai-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  menuItems: any;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly router: Router,
    private readonly footerService: FooterService
  ) {}

  ngOnInit(): any {
    this.footerService.init();

    this.footerService.menuItems$.subscribe((menuItems) => {
      if (menuItems) {
        this.menuItems = menuItems;
      }
    });
  }
}
