import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Capability } from "@shared/capability-carousel";
import { BehaviorSubject, Observable } from "rxjs";
import { BenefitsResponse } from "./benefits.models";

@Injectable({
    providedIn: "root",
})
export class BenefitsService {
    benefits$ = new BehaviorSubject([] as Capability[]);

    constructor(private http: HttpClient) { }

    init(): void {
        this.getData().subscribe((res) => this.benefits$.next(res?.benefits));
    }

    getData(): Observable<BenefitsResponse> {
        return this.http.get<BenefitsResponse>(
            "/assets/data/benefits.json"
        );
    }
}
