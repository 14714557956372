import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ImagesService {
    images$ = new BehaviorSubject({} as any);

    constructor(private http: HttpClient) { }

    init(): void {
        this.getImages().subscribe((imagesRes) => this.images$.next(imagesRes?.images));
    }

    getImages(): Observable<any> {
        return this.http.get("/assets/data/images.json");
    }
}
