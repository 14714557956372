import { Component } from '@angular/core';
import { ImagesService } from "./services/images.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    public logoUrl: string;
    public desktopHamburgerLogoUrl: string;

    constructor(private readonly imagesService: ImagesService) { }

    ngOnInit(): void {
        this.imagesService.init();
        this.imagesService.images$.subscribe((images) => {
            this.logoUrl = images.logo;
            this.desktopHamburgerLogoUrl = images.desktopHamburgerLogo;
        });
    }
}
