import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NguCarouselModule } from "@ngu/carousel";
import { DeloitteAiCapabilityCarouselComponent } from "./capability-carousel";

@NgModule({
    declarations: [
        DeloitteAiCapabilityCarouselComponent,
    ],
    imports: [
        CommonModule,
        NguCarouselModule
    ],
    exports: [
        DeloitteAiCapabilityCarouselComponent,
    ],
    providers: [],
})
export class DeloitteAiCapabilityCarouselModule { }
