
<ngu-carousel #myCarousel [inputs]="tileConfig" [dataSource]="items">
  <ngu-tile *nguCarouselDef="let item; let i = index">
    <div class="card cursor-pointer">
      <div class="body">
        <ng-container [ngTemplateOutlet]="item.templateRef">
        </ng-container>
      </div>
    </div>
  </ngu-tile>
</ngu-carousel>
