import { AiHubLoaderService } from "@aihub/ui/loader";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, finalize, take } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class DeloitteAiLoaderInterceptor implements HttpInterceptor {

    public static HIDE_LOADING_BAR = `DeloitteAiHideLoadingBar`;

    constructor(private loaderService: AiHubLoaderService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const hideLoadingBar = req.params.has(DeloitteAiLoaderInterceptor.HIDE_LOADING_BAR);
        let request = req;

        if (hideLoadingBar) {
            request = req.clone({
                params: request.params.delete(DeloitteAiLoaderInterceptor.HIDE_LOADING_BAR)
            });
        } else {
            this.loaderService.show();
        }

        return next.handle(request).pipe(
            filter((event) => event instanceof HttpResponse),
            finalize(() => {
                if (!hideLoadingBar) {
                    this.loaderService.hide();
                }
            }),
            take(1)
        );
    }
}
