<div class="container mx-auto mt-4">
  <div class="row">
    <div class="col-12">
      <div class="banner bg-white d-flex flex-row p-5 card shadow">
        <div class="mr-5 content">
          <div class="h3 mb-2">
            {{ "banner.heading" | translate }}
          </div>
          <p class="d-block mb-4 font-size-sm">
            {{ "banner.title" | translate }}
          </p>
          <div class="font-weight-bold cursor-pointer" (click)="download()">
            <a href="javascript:void(0)">{{"banner.download" | translate }}</a>
            <i class="material-icons font-size-lg">
              cloud_download
            </i>
          </div>
        </div>

        <div class="img d-none d-md-flex ml-auto">
          <img [src]="downloadBannerImage">
        </div>
      </div>
    </div>
  </div>
</div>