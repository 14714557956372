import { Component, Input } from "@angular/core";

@Component({
    selector: "deloitteai-page-banner",
    templateUrl: "./page-banner.html",
    styleUrls: ["./page-banner.scss"]
})
export class DeloitteAiPageBannerComponent {

    @Input()
    public imageUrl: string;

    @Input()
    public title: string;

    @Input()
    public text: string;
}
