<deloitteai-page-banner class="grid-10">
  <img src="./assets/img/faq-bannerimg.png" bannerImage>

  <ng-container bannerHeader>
    <h3 class="header">{{'FAQ.BANNER.HEADER' | translate}}</h3>
  </ng-container>

  <ng-container bannerBody>
    <p class="text">{{'FAQ.BANNER.TEXT' | translate}}</p>
  </ng-container>
</deloitteai-page-banner>

<div class="container m-auto">

  <!-- banner -->
  <div *ngFor="let section of faqSections$ | async" class="row flex-column section">
    <div class="section__header col px-3">{{section.name}}</div>

    <aihub-accordion [multi]="true" class="section__body col">

      <aihub-accordion-item class="accordion-item" *ngFor="let item of section.items">
        <div class="accordion-header" aihubAccordionItemHeader>
          {{item.question}}
        </div>

        <div class="accordion-body" aihubAccordionItemBody [innerHTML]="item.answerHtml">
        </div>
      </aihub-accordion-item>

    </aihub-accordion>
  </div>
</div>
