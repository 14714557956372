import { Route, Router } from "@angular/router";
import { Page404Component } from "./page_404";

/**
 * put the 404 route at the end of the router configuration
 * so that we dont affect other routes since ** simply matches everything
 */
export function PageNotFoundBootstrapFactory(router: Router) {
    return (): any => {
        const route404: Route = {
            path: "**",
            component: Page404Component
        };
        router.config.push(route404);
    };
}
