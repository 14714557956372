<deloitteai-page-banner class="grid-10">

    <img src="./assets/img/contact-bannerimg.png" bannerImage>

    <ng-container bannerHeader>
        <h3 class="header">{{'CONTACT.BANNER.HEADER' | translate}}</h3>
    </ng-container>

    <ng-container bannerBody>
        <p class="text">{{'CONTACT.BANNER.TEXT' | translate}}</p>
    </ng-container>

    <ng-container bannerFooter>
        <a class="btn btn-primary mt-2 w-xs-100" href="{{contactUs}}" target="_blank" rel="noopener noreferrer">
            {{'CALL_TO_ACTION.CONTACT_US' | translate}}
        </a>
    </ng-container>

</deloitteai-page-banner>

<div class="container m-auto px-3 contact-main">

    <div class="row mb-5 pb-3">
        <div class="col-md-10 offset-md-1 text-black bold text-center">
            <h3>{{'CONTACT.SUPPORT.HEADER' | translate}}</h3>
        </div>
    </div>

    <div class="row mb-5 pb-3">
        <div class="col-md-10 offset-md-1">
            <deloitteai-benefits>
                <ng-template deloitteaiBenefitsItem>
                    <!-- landing -->
                    <a (click)="showVideo($event)" [queryParams]="{section: 'video'}"
                        [innerHTML]="'CONTACT.SUPPORT.CARD.TUTORIAL' | translate"></a>
                </ng-template>

                <ng-template deloitteaiBenefitsItem>
                    <a [routerLink]="'/onboarding'" [innerHTML]="'CONTACT.SUPPORT.CARD.ONBOARDING' | translate"></a>
                </ng-template>

                <ng-template deloitteaiBenefitsItem>
                    <a [routerLink]="'/faq'" [innerHTML]="'CONTACT.SUPPORT.CARD.FAQ' | translate">
                    </a>
                </ng-template>
            </deloitteai-benefits>
        </div>
    </div>
</div>
