import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class PrivacyService {
    privacyPageContent$ = new BehaviorSubject({} as any);

    constructor(private http: HttpClient) { }

    init(): void {
        this.getContent().subscribe((res) => this.privacyPageContent$.next(res?.content));
    }

    getContent(): Observable<any> {
        return this.http.get("/assets/data/privacy-page.json");
    }
}
