import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DEFAULT_HEADER_MENU_ITEMS } from "./header.data";
import { HeaderMenuItems, HeaderMenuItemsResponse } from "./header.models";

@Injectable({
    providedIn: "root",
})
export class HeaderService {
    menuItems$ = new BehaviorSubject({} as any);

    constructor(private http: HttpClient) { }

    init(defaultMenuItems: HeaderMenuItems): void {
        if (defaultMenuItems) {
            this.menuItems$.next(defaultMenuItems);
        } else {
            this.getMenuItems().subscribe((res) => {
                const menuItems = res?.menuItems || DEFAULT_HEADER_MENU_ITEMS;

                this.menuItems$.next(menuItems);
            });
        }
    }

    getMenuItems(): Observable<HeaderMenuItemsResponse> {
        return this.http.get<HeaderMenuItemsResponse>("/assets/data/header.json");
    }
}
