export interface HeaderMenuItemsResponse {
  menuItems: HeaderMenuItems;
}

export interface HeaderMenuItems {
  home: HeaderMenuItem;
  center: HeaderMenuItem[];
  user?: HeaderMenuItem[];
}

export interface HeaderMenuItem {
  label: string;
  route: string;
  isExternal?: boolean;
}
