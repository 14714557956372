import { Component } from "@angular/core";

@Component({
    selector: "aihub-not-found",
    templateUrl: "./page_404.html",
    styleUrls: ["./page_404.scss"]
})
export class Page404Component {

}
