<div class="link-item">
  <ng-content select="[linkDescription]"></ng-content>
  <div class="link">
    <a class="" [href]="url">
      <ng-content select="[linkActionText]"></ng-content>
    </a>
    <img
      class="primary-blue-filter"
      src="assets/img/icons/arrow_right.svg"
      alt=""
    />
  </div>
</div>
