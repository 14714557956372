import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DEFAULT_FOOTER_MENU_ITEMS } from "./footer.data";

@Injectable({
    providedIn: "root",
})
export class FooterService {
    menuItems$ = new BehaviorSubject({} as any);

    constructor(private http: HttpClient) { }

    init(): void {
        this.getMenuItems().subscribe((res) => {
            const menuItems = res || DEFAULT_FOOTER_MENU_ITEMS;
            this.menuItems$.next(menuItems);
        });
    }

    getMenuItems(): Observable<any> {
        return this.http.get<any>("/assets/data/footer.json");
    }
}
