import { AiHubAuthModule } from "@aihub/core/authorization";
import { AiHubLoginPageModule } from "@aihub/ui/login-page";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { environment } from '@environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DeloitteAiPageModule } from "@shared/page";
import { DownloadComponent } from '../app/components/download.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InternationalizationModule } from "./internationalization/internationalization.module";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, "../assets/locales/", ".json");
}

@NgModule({
    declarations: [
        AppComponent,
        DownloadComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        DeloitteAiPageModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        InternationalizationModule.forRoot({ locale_id: "en-US" }),
        AiHubAuthModule.forRoot({
            firebase: environment.firebase,
            params: environment.authParams,
            signInType: environment.signInType,
            jwtInterceptHook: (uri: string) => uri.startsWith(environment.brain_base_url)
        }),
        AiHubLoginPageModule.forRoot(false)
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
