import { Component, ContentChildren, QueryList } from "@angular/core";
import { NguCarouselConfig } from "@ngu/carousel";
import { DeloitteaiBenefitsItemDirective } from "./benefits-item";

@Component({
    selector: "deloitteai-benefits",
    templateUrl: "./benefits.html",
    styleUrls: ["./benefits.scss"]
})
export class DeloitteAiBenefitsComponent {

    public tileConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 2, md: 2, lg: 3, all: 0 },
        speed: 250,
        load: 2,
        velocity: 0,
        touch: true,
        easing: "cubic-bezier(0, 0, 0.2, 1)",
        point: {
            visible: true,
            hideOnSingleSlide: true
        }
    };

    public items: DeloitteaiBenefitsItemDirective[] = [];

    @ContentChildren(DeloitteaiBenefitsItemDirective, { read: DeloitteaiBenefitsItemDirective, descendants: false })
    public set benefitItems(itemList: QueryList<DeloitteaiBenefitsItemDirective>) {
        this.items = itemList.toArray();
    }
}
