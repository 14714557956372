import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { DeloitteAiCommonModule } from "@shared/common/common.module";

import { MenuItemPipe } from "./menu-item.pipe";
import { HeaderComponent } from "./header";
@NgModule({
    declarations: [
        HeaderComponent,
        MenuItemPipe
    ],
    imports: [
        CommonModule,
        DeloitteAiCommonModule,
        MatMenuModule,
        RouterModule,
        TranslateModule.forChild(),
        MatIconModule
    ],
    exports: [
        HeaderComponent
    ],
    providers: [],
})
export class DeloitteAiHeaderModule { }
