export const URL_REGEX = /^(https):\/\/[^ "]+$/;
export const ALPHA_NUMERIC_REGEX = /(^[a-zA-Z0-9_-]+$)/;
export const ALPHA_NUMERIC_SPACE_REGEX = /^[a-zA-Z0-9_ -öüäÖÜÄ]+$/;

export enum FILE_TYPES {
    JSON = ".json",
    YML = ".yml",
    YAML = ".yaml",
    PDF = ".pdf",
    TXT = ".txt",
    JPG = ".jpg",
    JPEG = ".jpeg",
    PNG = ".png",
    CSV = ".csv",
    XLS = ".xls",
    XLSX = ".xlsx",
    DOC = ".doc",
    DOCX = ".docx",
    PPT = ".ppt",
    PPTX = ".pptx",
    XML = ".xml"
}

export const enum INPUT_TYPES {
    TEXT = "text",
    PASSWORD = "password"
}

export enum MAX_LENGTH {
    SUB_DOMAIN = 63
}

export const CURRENCY_SYMBOLS = [
    {
        "code": "USD", "symbol": "\u0024"
    },
    {
        "code": "EUR", "symbol": "\u20AC"
    }
]