
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AuthService } from "@aihub/core/authorization";
import { take } from "rxjs/operators";
import firebase from "firebase/auth";

@Injectable()
export class DeloitteAiUserResolver  {

    constructor(
        private authService: AuthService
    ) {}

    resolve(): Observable<firebase.User> {
        return this.authService.getUser().pipe(take(1));
    }
}
