<div class="container mx-auto page__404">

  <div class="row text-center">

    <div class="col-12 page__404--header">
      <img src="assets/img/robot.svg" alt="">
      <h2>Oops! Page not found.</h2>
    </div>

    <div class="col-12 page__404--body">
      The page you were looking  for was moved or doesn´t exist.
    </div>

  </div>

</div>
