import { Directive, TemplateRef } from "@angular/core";

@Directive({
    selector: "[deloitteaiBenefitsItem]",
})
export class DeloitteaiBenefitsItemDirective {

    constructor(
        public templateRef: TemplateRef<unknown>
    ) {}
}
