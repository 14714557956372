import { Component, OnInit } from "@angular/core";
import { FaqService } from "./faq.service";

@Component({
    selector: "deloitteai--faq-page",
    templateUrl: "./faq.html",
    styleUrls: ["./faq.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FaqPage implements OnInit {
    faqSections$ = this.faqService.faqSections$;

    constructor(private readonly faqService: FaqService) { }

    ngOnInit(): void {
        this.faqService.init();
    }
}
