<header class="shared-header" *ngIf="!mobileUser" style="width: 100%">
    <nav class="navbar navbar-expand-sm fixed-top d-inline-flex">
        <!-- left -->
        <div class="navbar-collapse collapse navbar-left order-1 order-md-0 dual-collapse2">
            <ul class="navbar-nav mr-auto" menu-eft>
                <a href="{{menuItems?.home | menuItem}}" class="d-inline-flex">
                    <img [src]="logoUrl" alt="Logo" class="img-fluid header-logo my-auto d-none d-md-block" />
                </a>
            </ul>
        </div>

        <!-- center -->
        <div class="navbar-collapse collapse w-100 order-2 dual-collapse2 d-block">
            <ng-content *ngIf="userLoggedIn"></ng-content>
            <ul class="navbar-nav w-100 justify-content-center" *ngIf="userLoggedIn">
                <li *ngFor="let item of menuItems?.center">
                    <a href="{{item | menuItem}}" class="static-nav-item font-weight-bold text-white"
                        [attr.target]="item.openInNewTab ? '_blank' : null"
                        [attr.rel]="item.openInNewTab ? 'noopener noreferrer' : null">
                        {{item.label | translate}}
                    </a>
                </li>
            </ul>
        </div>

        <!-- right -->
        <div class="navbar-collapse collapse navbar-right order-3 dual-collapse2" *ngIf="isUserResolved">
            <ng-content *ngIf="!mobileUser"></ng-content>

            <ul class="navbar-nav ml-auto">
                <li class="nav-item d-inline-flex mr-4" *ngIf="!userLoggedIn">
                    <img class="d-block my-auto" src="../../../../assets/img/wordmark_ai_small.svg" />
                </li>

                <li class="nav-item d-inline-flex h-100" *ngIf="userLoggedIn"
                    [ngClass]="{'rounded': isRoundedHamburgerLogo}">
                    <button mat-button [matMenuTriggerFor]="userMenu" #trigger="matMenuTrigger"
                        [ngClass]="{'active': trigger.menuOpen}">
                        <img class="hamburger-logo" [src]="desktopHamburgerLogoUrl" />
                    </button>
                </li>
            </ul>
        </div>
    </nav>

    <!-- usermenu -->
    <mat-menu #userMenu="matMenu" class="deloitteai__header--usermenu" (closed)="onUsermenuClose($event)">
        <div mat-menu-item class="cursor-none text-white user">
            <mat-icon style="color: white" class="m-0">perm_identity</mat-icon><span
                *ngIf="userLoggedIn">{{user.displayName}}</span>
        </div>

        <ul class="list-unstyled mb-0 bg-grey">
            <li mat-menu-item class="cursor-pointer" *ngFor="let item of menuItems?.user">
                <a href="{{item | menuItem}}" class="static-nav-item"
                    [attr.target]="item.openInNewTab ? '_blank' : null"
                    [attr.rel]="item.openInNewTab ? 'noopener noreferrer' : null">
                    {{item.label | translate}}
                </a>
            </li>

            <li mat-menu-item class="cursor-pointer w-100" (click)="logout($event)">
                <a href="#" class="static-nav-item text-white"> Logout </a>
            </li>
        </ul>
    </mat-menu>
</header>

<!-- USER MENU -->
<header *ngIf="mobileUser && userLoggedIn" class="mobile-header">
    <nav class="navbar navbar-expand fixed-top">
        <div [matMenuTriggerFor]="hamburgerMenu" #mobileBurgerMenuTrigger="matMenuTrigger"
            (menuOpened)="onOpenMenu(mobileBurgerMenuTrigger)" [ngClass]="{'active': mobileBurgerMenuTrigger.menuOpen}"
            *ngIf="userLoggedIn">
            <img src="../../../../assets/img/Menu.svg" class="float-right" />
        </div>

        <a href="{{menuItems?.home | menuItem}}" class="text-center d-inline-flex">
            <img width="100%;" [src]="logoUrl" alt="Logo" class="img-fluid header-logo my-auto" />
        </a>

        <div #mobileUserMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="userMenu"
            (menuOpened)="onOpenMenu(mobileUserMenuTrigger)" [ngClass]="{'active': mobileUserMenuTrigger.menuOpen}"
            *ngIf="userLoggedIn">
            <img src="../../../../assets/img/User.svg" />
        </div>
    </nav>

    <!-- user menu mobile -->
    <mat-menu #userMenu="matMenu" class="deloitteai__header--usermenu" (closed)="onUsermenuClose()">
        <ul class="list-unstyled mb-0 bg-grey">
            <li mat-menu-item class="cursor-none text-white bg-green">
                <mat-icon style="color: white" class="m-0">perm_identity</mat-icon>
                <span style="font-weight: bold">{{user.displayName }}</span>
            </li>

            <li mat-menu-item class="cursor-pointer text-white" *ngFor="let item of menuItems?.user">
                <a (click)="navigateTo($event, item.route)"
                    class="static-nav-item font-weight-bold text-white">{{item.label | translate}}</a>
            </li>

            <li mat-menu-item class="cursor-pointer w-100 text-white" (click)="logout($event)">
                <a href="#" class="static-nav-item font-weight-bold text-white">
                    Logout</a>
            </li>
        </ul>
    </mat-menu>

    <mat-menu #hamburgerMenu="matMenu" class="deloitteai__header--usermenu" (closed)="onUsermenuClose()">
        <ul class="list-unstyled mb-0 bg-grey">
            <li mat-menu-item class="cursor-pointer text-white">
                <a [routerLink]="['./onboarding']" class="static-nav-item font-weight-bold text-white">Onboarding</a>
            </li>

            <li mat-menu-item *ngFor="let item of menuItems?.center" class="cursor-pointer text-white">
                <a href="{{item.route | menuItem }}" class="static-nav-item font-weight-bold text-white">
                    {{item.label}}
                </a>
            </li>

            <li mat-menu-item class="cursor-pointer w-100 text-white">
                <a [routerLink]="['./faq']" class="static-nav-item font-weight-bold text-white">Faq</a>
            </li>

            <li mat-menu-item class="cursor-pointer w-100 text-white no-border">
                <a [routerLink]="['./contact']" class="static-nav-item font-weight-bold text-white">Contact</a>
            </li>
        </ul>
    </mat-menu>
</header>

<header *ngIf="mobileUser && !userLoggedIn" class="mobile-header">
    <nav class="navbar navbar-expand fixed-top light">
        <a href="{{menuItems?.home | menuItem}}" class="text-center d-flex w-100 justify-content-between px-3">
            <img width="100%;" [src]="logoUrl" alt="Logo" class="img-fluid header-logo my-auto" />
            <img width="100%;" src="../../../../assets/img/wordmark_ai.svg" alt="Logo"
                class="img-fluid header-logo my-auto" />
        </a>
    </nav>
</header>