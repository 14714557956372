<div class="deloitteai__banner">
    <div class="deloitteai__banner--inner">
        <!-- left -->
        <div class="content__left">
            <div class="content__logo">
                <img src="assets/img/support.svg" alt="" />
            </div>

            <div class="content__info">
                <div class="content__info--title">
                    <ng-content select="[bannerTitle]"></ng-content>
                </div>

                <ng-content select="[bannerInfo]"></ng-content>

                <ng-content select="[bannerActionButton]"></ng-content>
            </div>
        </div>

        <!-- center line -->
        <div class="content__separator"></div>

        <!-- right -->
        <div class="content__right d-flex align-items-center">
            <div class="content__links">
                <ng-content select="[bannerLinks]"></ng-content>
            </div>
        </div>
    </div>
</div>