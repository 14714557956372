import { Component, Input } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
    selector: "deloitteai-page",
    templateUrl: "./page.html",
    styleUrls: ["./page.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DeloitteAiPage {
    @Input() logoUrl: string;
    @Input() desktopHamburgerLogoUrl: string;

    public isInitialized = false;

    protected subscriptions: Subscription[] = [];
}
