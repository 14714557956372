import { Route, Router } from "@angular/router";
import { DeloitteAiContactPage } from "./contact/contact";
import { FaqPage } from "./faq/faq";
import { ImprintPage } from "./imprint/imprint";
import { ImprintService } from "./imprint/imprint.service";
import { OnboardingPage } from "./onboarding/onboarding";
import { PrivacyPage } from "./privacy/privacy.component";
import { PrivacyService } from "./privacy/privacy.service";
import { TermsOfUsePage } from "./terms/terms-of-use.component";
import { TermsOfUsePageService } from "./terms/terms-of-use.service";

/**
 * put the 404 route at the end of the router configuration
 * so that we dont affect other routes since ** simply matches everything
 */
export function DeloitteAiCommonRoutesFactory(
    router: Router,
    imprintService: ImprintService,
    privacyService: PrivacyService,
    termsOfUseService: TermsOfUsePageService
): any {
    imprintService.init();
    privacyService.init();
    termsOfUseService.init();

    return () => {
        const routes: Route[] = [
            {
                path: "impressum",
                component: ImprintPage,
                data: {
                    content$: imprintService.imprintPageContent$,
                },
            },
            {
                path: "terms-of-use",
                component: TermsOfUsePage,
                data: {
                    content$: termsOfUseService.TermsOfUsePageContent$,
                },
            },
            {
                path: "privacy",
                component: PrivacyPage,
                data: {
                    content$: privacyService.privacyPageContent$,
                },
            },
            {
                path: "onboarding",
                component: OnboardingPage,
            },
            {
                path: "faq",
                component: FaqPage,
            },
            {
                path: "contact",
                component: DeloitteAiContactPage,
            },
        ];

        /** find ** route */
        const config = router.config;
        let insertAt = 0;
        for (let i = 0; i < config.length; i++) {
            const route = routes[i];

            if (route.path === "**") {
                insertAt = i;
            }
        }
        router.config.splice(insertAt, 0, ...routes);
    };
}
