import { AuthService } from "@aihub/core/authorization";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatMenu, MatMenuTrigger } from "@angular/material/menu";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "@environments/environment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HeaderMenuItems } from "./header.models";
import { HeaderService } from "./header.service";

@Component({
    selector: "deloitteai-header",
    templateUrl: "./header.html",
    styleUrls: ["./header.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
    defaultHamburgerLogo = "/assets/img/logo_small.svg";

    @ViewChild(MatMenuTrigger)
    private trigger: MatMenuTrigger;

    @ViewChild("userMenu")
    usermenu: MatMenu;

    @ViewChild("hamburgerMenu")
    hamburgerMenu: MatMenu;

    @Input()
    logoUrl = "/assets/img/deloitte-ai-logo.svg";

    @Input()
    desktopHamburgerLogoUrl = "/assets/img/logo_small.svg";

    @Input() menuItems: HeaderMenuItems;

    @Input()
    appName = "Deloitte AI";

    @Input()
    signUpEnabled = false;

    userMenuActive = false;
    userLoggedIn = false;
    isInitComplete = false;
    isUserResolved = false;
    isOnLoginPage = true;
    isOnSignUpPage = false;
    user: any; // IUser;
    mobileUser = true;
    environment = environment;
    isRoundedHamburgerLogo = false;

    private destroy$: Subject<boolean> = new Subject();
    private currentMenuCtrl: MatMenuTrigger;

    constructor(
        private readonly loginService: AuthService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly router: Router,
        private readonly headerService: HeaderService
    ) { }

    ngOnInit(): any {
        this.headerService.init(this.menuItems);

        this.headerService.menuItems$.subscribe((menuItems) => {
            if (menuItems) {
                this.menuItems = menuItems;
            }
        });

        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            this.isOnSignUpPage = event.url === "/register";
        });

        this.breakpointObserver
            .observe([Breakpoints.XSmall, Breakpoints.Small])
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                this.mobileUser =
                    result.breakpoints[Breakpoints.XSmall] ||
                    result.breakpoints[Breakpoints.Small];
            });

        this.loginService
            .getUser()
            .pipe(takeUntil(this.destroy$))
            .subscribe((user) => {
                this.isUserResolved = true;
                this.userLoggedIn = !!user;
                this.user = user;
            });

        this.loginService
            .getJwtToken()
            .pipe(takeUntil(this.destroy$))
            .subscribe((jwt: string) => {
                if (jwt) {
                    const parsedJwt = JSON.parse(atob(jwt.split(".")[1]));
                    if (parsedJwt?.firebase?.sign_in_provider.includes('google.com')) {
                        this.desktopHamburgerLogoUrl = parsedJwt.picture || this.defaultHamburgerLogo;
                        this.isRoundedHamburgerLogo = true;
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
    }

    logout(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.userLoggedIn = false;
        this.loginService.logout().subscribe(() => this.router.navigate(["login"]));
    }

    onOpenMenu(menuTrigger: MatMenuTrigger): void {
        if (this.currentMenuCtrl) {
            this.currentMenuCtrl.closeMenu();
        }
        this.currentMenuCtrl = menuTrigger;
    }

    onUsermenuClose(): void {
        this.currentMenuCtrl = null;
    }

    navigateTo($event: MouseEvent, route: string): void {
        $event.preventDefault();
        $event.stopPropagation();

        this.router.navigate([route]);
    }
}
