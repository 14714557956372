import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

interface DownloadUri {
    url: string;
}

const downloadUrl = environment.brain_base_url + '/fileservice/download';

@Injectable({ providedIn: 'root' })
export class DownloadService {
    public constructor(private http: HttpClient) { }

    public fetchDownloadUrl(params: any): Observable<string> {
        return this.http.get<DownloadUri>(downloadUrl + '', {
            params
        }).pipe(map((data) => data.url));
    }
}
