import { Component, Inject, OnInit, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { NguCarouselConfig } from "@ngu/carousel";
import { DELOITTEAI_ENVIRONMENT } from "@shared/environment";
import { environment } from "@environments/environment";

@Component({
    selector: "deloitteai-contact",
    templateUrl: "./contact.html",
    styleUrls: ["./contact.scss"]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DeloitteAiContactPage implements OnInit {

    public tileConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 2, md: 2, lg: 3, all: 0 },
        speed: 250,
        load: 2,
        velocity: 0,
        touch: true,
        easing: "cubic-bezier(0, 0, 0.2, 1)",
        point: {
            visible: true,
            hideOnSingleSlide: true
        }
    };

    public scope: string;
    public contactUs = environment.support.contact || "";

    public constructor(
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        @Optional() @Inject(DELOITTEAI_ENVIRONMENT) private envVariables: any,
        private router: Router
    ) { }

    public ngOnInit(): any {
        // eslint-disable-next-line no-prototype-builtins
        const scopeVar = this.envVariables?.find((data: any) => data.hasOwnProperty("scope"));
        this.scope = scopeVar?.scope ?? "unknown";
    }

    public showVideo($event: MouseEvent): any {
        $event.preventDefault();
        $event.stopPropagation();

        if (this.scope !== `landing`) {
            window.location.href = "https://deloitte.ai?show=tutorial";
            return;
        }

        this.router.navigate(["/"], {
            queryParams: {
                show: `tutorial`
            }
        });
    }
}
