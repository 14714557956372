import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class TermsOfUsePageService {
    TermsOfUsePageContent$ = new BehaviorSubject({} as any);
    public environment = environment;

    constructor(private http: HttpClient) { }

    init(): void {
        this.getContent().subscribe((res) => this.TermsOfUsePageContent$.next(res?.content));
    }

    getContent(): Observable<any> {
        return this.http.get(`${this.environment.brain_base_url}/terms/terms/tou`);
    }
}
