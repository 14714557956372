// vjs-player.component.ts
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import videojs from "video.js";
import Player from "video.js/dist/types/player";

@Component({
    selector: "deloitteai-video-player",
    template: `
    <video #target class="video-js" controls muted playsinline preload="none"></video>
  `,
    styleUrls: ["./vjs-player.scss"]
})
export class DeloitteAiVjsPlayerComponent implements OnInit, OnDestroy {

    @ViewChild("target", { static: true, read: ElementRef })
    target: ElementRef;

    // see options: https://github.com/videojs/video.js/blob/mastertutorial-options.html
    @Input()
    options: Object;

    player: Player;

    ngOnInit(): void {
        // instantiate Video.js
        this.player = videojs(this.target.nativeElement, this.options);
    }

    ngOnDestroy(): void {
        if (this.player) {
            this.player.dispose();
        }
    }
}
